import * as api from '../services/api';

export const types = {
    GET_EXPERIENCE_LOADING: 'GET_EXPERIENCE_LOADING',
    GET_EXPERIENCE_ERROR: 'GET_EXPERIENCE_ERROR',
    GET_EXPERIENCE_SUCCESS: 'GET_EXPERIENCE_SUCCESS',

    GET_EXPERIENCE_STATE_LOADING: 'GET_EXPERIENCE_STATE_LOADING',
    GET_EXPERIENCE_STATE_ERROR: 'GET_EXPERIENCE_STATE_ERROR',
    GET_EXPERIENCE_STATE_SUCCESS: 'GET_EXPERIENCE_STATE_SUCCESS',

    JUMP_TO_SLIDE: 'JUMP_TO_SLIDE',
}

const getExperienceLoading = () => ({ type: types.GET_EXPERIENCE_LOADING });
const getExperienceError = (message) => ({ type: types.GET_EXPERIENCE_ERROR, payload: message });
const getExperienceSuccess = (experience) => ({ type: types.GET_EXPERIENCE_SUCCESS, payload: experience });

export function getExperience(id) {
    return async (dispatch) => {
        dispatch(getExperienceLoading());
        try {
            const { data: experience } = await api.getExperienceData(id);
            dispatch(getExperienceSuccess(experience));
            return experience;
        } catch (err) {
            dispatch(getExperienceError(err));
        }
    }
}

const getExperienceStateLoading = () => ({ type: types.GET_EXPERIENCE_STATE_LOADING });
const getExperienceStateError = (message) => ({ type: types.GET_EXPERIENCE_STATE_ERROR, payload: message });
const getExperienceStateSuccess = (state) => ({ type: types.GET_EXPERIENCE_STATE_SUCCESS, payload: state });

export function getExperienceState(id) {
    return async (dispatch) => {
        dispatch(getExperienceStateLoading());
        try {
            const { data: state } = await api.getCurrentState(id);
            dispatch(getExperienceStateSuccess(state));
        } catch (err) {
            dispatch(getExperienceStateError(err));
        }
    }
}

export const jumpToSlide = (slideIndex) => ({ type: types.JUMP_TO_SLIDE, payload: slideIndex });