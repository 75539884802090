import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import { getExperience, getExperienceState, jumpToSlide } from '../../actions/experiences';
import { Preview } from '@splashmob-inc/splashmob-ui';

let socket;

const Controller = ({
    experience, currentSlide, loading,
    match,
    getExperience,
    getExperienceState,
    jumpToSlide,
}) => {

    const { experienceId } = match.params;

    useEffect(() => {
        (async () => {
            const experienceData = await getExperience(experienceId);
            await getExperienceState(experienceId);
            socket = io(`/${experienceData.shortId}`, { query: { experienceId, clientType: 'controller' } });
            socket.on('jump-to-slide', jumpToSlide);
        })();

        return function cleanup() {
            socket.disconnect();
            socket.off();
        }
    }, [experienceId, getExperience, getExperienceState, jumpToSlide]);

    const { GET_EXPERIENCE_LOADING, GET_EXPERIENCE_STATE_LOADING } = loading;
    if (!experience || currentSlide === null || [GET_EXPERIENCE_LOADING, GET_EXPERIENCE_STATE_LOADING].includes(true)) {
        return <h1>Loading</h1>
    }

    return (
        <>
            <Preview slide={experience.slides[currentSlide]} />
        </>
    )
}

const mapStateToProps = ({
    experience,
    currentSlide,
    loading,
}) => ({ experience, currentSlide, loading })
export default connect(mapStateToProps, { getExperience, getExperienceState, jumpToSlide })(Controller);