import { types } from '../actions/experiences';

export default (state = null, action) => {
    switch (action.type) {
        case types.GET_EXPERIENCE_STATE_SUCCESS:
            return action.payload.currentSlide;
        case types.JUMP_TO_SLIDE:
            return action.payload;
        default: return state;
    }
}