import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000000;
    background: rgba(0, 0, 0, .7);
    text-align: center;
    display: grid;
    align-items: center;
    justify-content: center;
`

const InnerContainer = styled.div`
    height: 90%;
    display: flex;
    flex-direction: column;
    width:300px;
    & h4 {
        padding:10px 30px;
        font-weight:normal;
    }
    & h2 {
        padding: 30px;
    }
`

const IconRow = styled.div`

    display: flex;
    width:75%;
    margin:0 auto;
    padding:10px;
    
    & img {
      margin: auto;
      width: 40px;
      height:40px;
      object-fit: contain;
      align-self: flex-start;
    }
    & div {
      flex: 1 1 auto;
      & h4, h5 {
        line-height:1;
        text-align: left;
        font-weight:normal !important;
        margin: 10px 0px;
        padding: 0px 20px;
      }
    }
`

const TapToStart = ({ onClick }) => {
    return (
        <MainContainer onClick={onClick}>
            <InnerContainer>

                <h1>This is a Story!</h1>
                <h4>Use the following gestures to navigate: </h4>
                <IconRow>
                    <img src="https://splashmob-experiences.s3-us-west-2.amazonaws.com/splashmob/assets/icons/tap-white.png" alt=""/> <div><h4>Go Forward</h4> <h5>Tap the screen</h5></div>
                </IconRow>
                <IconRow>
                    <img src="https://splashmob-experiences.s3-us-west-2.amazonaws.com/splashmob/assets/icons/tap1white.png" alt=""/><div><h4>Go Back</h4> <h5>Tap the left edge</h5></div>
                </IconRow>
                <h2> Tap to start</h2>
            </InnerContainer>
        </MainContainer>
    );
}

export default TapToStart;

// & h1 {
//     font-size: 1.5em;
//     text-align: center;
//     padding: 30px 60px;
//     font-weight: 500;
//     text-transform: uppercase;
//     box-shadow: 0px 0px 30px rgba(0, 0, 0, .5);
//     background: rgba(0, 0, 0, .3);
// }