import { types } from '../actions/user';

const defaultState = { userInteracted: false }

export default (state = defaultState, action) => {
    switch (action.type) {
        case types.USER_INTERACTED:
            return { ...state, userInteracted: true };
        default: return state;
    }
}