import axios from 'axios';

export async function getExperienceData(experienceId) {
    return axios.get(`/data/${experienceId}`);
}

export async function getCurrentState(experienceId) {
    return axios.get(`/current-state/${experienceId}`);
}

export async function dataCapture(data) {
    return axios.post('/data-capture', data);
}