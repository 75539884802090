import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import styled from 'styled-components';
import { getExperience, getExperienceState, jumpToSlide } from '../../actions/experiences';
import { dataCapture } from '../../services/api';
import UAParser from 'ua-parser-js';
import NoSleep from 'nosleep.js';
import useSound from 'use-sound';
import { Experience as ExperienceUI, Loading } from '@splashmob-inc/splashmob-ui';
import TapToStart from './TapToStart';
import initialAudio from './initialAudio.mp3';
import heap from '../../vendor/heap';
import './Experience.css';

let clientType = window.location === window.parent.location ? 'experience' : 'experience-preview';
let socket;

const uaParser = new UAParser();
const userDevice = uaParser.getDevice();
const userOS = uaParser.getOS();
const noSleep = new NoSleep();
const desktopMode = userDevice.type !== 'mobile' ? true : false ;

if(desktopMode) {
    document.getElementById('sdofjsdlknnsldkjn22l05lkn5').style.display = "none";
}

function preventSleep() {
    const iosTargetVersions = ['13', '14', '15'];
    if (userOS.name === 'iOS' && iosTargetVersions.includes(userOS.version.substring(0, 2))) {
        const wakeypoo = document.getElementById('sdofjsdlknnsldkjn22l05lkn5');
        wakeypoo.muted = false;
        wakeypoo.play();
        window.smNoSleep = setInterval(function () {
            var randomTime = Math.round(Math.random() * (getRandomTimestamp(wakeypoo) / 2))
            wakeypoo.currentTime = randomTime
        }, 5000);
    } else if (userDevice.type === 'mobile'){
        noSleep.enable();
    }
}

function getRandomTimestamp (video) {
    return video.duration;
}

const ExperienceContainer = styled.div`
    height: 100%;
    width: 100%;
    transition: filter 0.2s;
    filter: ${({ blur }) => blur ? 'blur(20px)' : 'blur(0px)'};

    & * {
        box-sizing: border-box;
    }
`

const NextButton = styled.div`
    right:20%;
`

const BackButton = styled.div`
    left:20%;
`

const Experience = ({
    experience, currentSlide, loading,
    match,
    getExperience,
    getExperienceState,
    jumpToSlide,
}) => {
    const { shortId } = match.params;
    const [userInteracted, setUserInteracted] = useState(false);

    const handleWindowFocus = () => {
        clearInterval(window.smNoSleep);
    }

    useSound(initialAudio); //set up audio context on load;
    useEffect(() => {
        (async () => {
            const experience = await getExperience(shortId);
            await getExperienceState(shortId);
            window.heap.track('loaded');
            if(experience.styles){
                var css = document.createElement('style');
                css.type = 'text/css';
                css.appendChild(document.createTextNode(experience.styles));
                document.head.appendChild(css);
            }

            socket = io(`/${shortId}`, {
                autoConnect: !experience.storyMode,
                query: {
                    experienceId: shortId,
                    clientType
                }
            });

            socket.on('jump-to-slide', function (slideIndex) {
                jumpToSlide(slideIndex);
            });

            socket.on('refresh', function(){
                window.location.reload()
            });

            setInterval(()=> {
                window.heap.track('heartbeat', {time:10});
            },10000);

            window.addEventListener('focus', handleWindowFocus);
        })();

        return function cleanup() {
            socket.disconnect();
            socket.off();
            window.removeEventListener('focus', handleWindowFocus);
        }

    }, [shortId, getExperience, getExperienceState, jumpToSlide])

    const postDataResult = (payload) => {
        if (payload.type === 'FORM_SUBMISSION') {
            const data = { experienceShortId: shortId, payload }
            return new Promise(async (resolve, reject) => {
                const { data: { success } } = await dataCapture(data);
                if (success) resolve(success);
                else reject(success)
            })
        } else {
            return new Promise((resolve, reject) => {
                payload.slideIndex = currentSlide;
                if (experience.storyMode) socket.connect();
                socket.emit('data-capture', { shortId, payload }, (success) => {
                    if (success) resolve(success);
                    else reject(success);
                    if (experience.storyMode) socket.disconnect();
                });
            })
        }
    }

    const trackAction = (payload) => {
        window.heap.track('customaction', payload)
    }

    const handleTapToStart = () => {
        setUserInteracted(true);
        preventSleep();
    }

    const previousSlide = () => {
        let index = Math.max(0, currentSlide - 1);
        window.heap.track('jump', {index: index});
        jumpToSlide(index);
    }

    const nextSlide = () => {
        let index = Math.min(experience.slides.length - 1, currentSlide + 1);
        window.heap.track('jump', {index: index})
        jumpToSlide(index);
    }

    const navigateStory = direction => {
        if (direction === 'forward') nextSlide();
        else previousSlide();
    }

    const { GET_EXPERIENCE_LOADING, GET_EXPERIENCE_STATE_LOADING } = loading;
    if (!experience || currentSlide === null || [GET_EXPERIENCE_LOADING, GET_EXPERIENCE_STATE_LOADING].includes(true)) {
        return (
            <ExperienceContainer className="Experience">
                <Loading />
            </ExperienceContainer>
        )
    }

    const isPreview = clientType === 'experience-preview'

    const storySlideDuration = experience.slides[currentSlide].autoplay.duration || experience.defaultSlideDuration;

        return (
            <>
                {!userInteracted && <TapToStart onClick={handleTapToStart} />}
                {desktopMode && experience.storyMode && (

                    <>
                        <NextButton className="storyBtn" onClick={nextSlide}>→</NextButton>
                        <BackButton className="storyBtn" onClick={previousSlide}>←</BackButton>
                    </>
                )}
                <ExperienceContainer
                    id="experience"
                    className={`Experience ${(!isPreview && desktopMode) ? 'DesktopMode' : ''}`}
                    blur={!userInteracted}
                >
                    <ExperienceUI
                        data={experience}
                        currentSlide={currentSlide}
                        onDataSubmit={postDataResult}
                        onDropletAction={trackAction}
                        muteAudio={isPreview}
                        storyMode={experience?.storyMode}
                        storyAutoplay={experience?.storyAutoplay}
                        storySlideDuration={storySlideDuration}
                        onStoryNavigation={navigateStory}
                    />
                </ExperienceContainer>
            </>
        )
}

const mapStateToProps = ({
    experience,
    currentSlide,
    loading,
}) => ({ experience, currentSlide, loading })
export default connect(mapStateToProps, { getExperience, getExperienceState, jumpToSlide })(Experience);