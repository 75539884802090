import React from "react";
import { Switch, Route } from "react-router-dom";
import Controller from '../Controller';
import Experience from "../Experience";
import './App.css';

const App = () => (
    <div className="App">
        <Switch>
            <Route path="/controller/:experienceId" component={Controller} />
            <Route path="/xp/:shortId" component={Experience} />
        </Switch>
    </div>
);

export default App;
