import { combineReducers } from 'redux';
import experienceReducer from './experience';
import currentSlideReducer from './currentSlide';
import loadingReducer from './loading';
import userReducer from './user';

export default combineReducers({
    experience: experienceReducer,
    currentSlide: currentSlideReducer,
    loading: loadingReducer,
    user: userReducer,
});